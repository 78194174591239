import React, { Component, Fragment } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import { Globalstyle } from './style';
import Header from './common/header';
import Home from './pages/home';
import Detail from './pages/detail/loadable.js';
import Login from './pages/login';
import Write from './pages/write';
import store from './store';
// function App() {
//   return (
//     <Fragment>
//       <Globalstyle />
//       <div className="dell">
//         hello
//     </div>
//     </Fragment>
//   );
// }
class App extends Component {
  render() {
    return (
      <Fragment>
        <Globalstyle />
        <Provider store={store}>
          <BrowserRouter>
            <div>
              <Header />
              {/* exact表示路径完全匹配 */}
              {/* <Route path='/' exact render={() => <div>home</div>}></Route> */}
              <Route path='/' exact component={Home}></Route>
              <Route path='/login' exact component={Login}></Route>
              <Route path='/write' exact component={Write}></Route>
              <Route path='/detail/:id' exact component={Detail}></Route>
              {/* <Route path='/detail' exact component={Detail}></Route> */}
            </div>
          </BrowserRouter>
        </Provider>
      </Fragment>
    )
  }
}

export default App;
