import axios from 'axios';
import * as constants from './constants';
import {fromJS} from 'immutable';
const changeHomeData = (result) => {
  console.log(result)
  return {
    type: constants.CHANGE_HOME_DATA,
    topicList: result.topicList,
    articleList: result.articleList,
    recommendList: result.recommendList
  }
}
const addHomeData = (list,nextPage) => {
  console.log(list)
  return {
    type: constants.ADD_ARTICLE_LIST,
    list:fromJS(list),
    nextPage
  }
}
export const getHomeInfo = () => {
  return (dispatch) => {
    axios.get('/api/home.json').then((res) => {
      const result = res.data.data;
      // const action = {
      //   type: 'change_home_data',
      //   topicList: result.topicList,
      //   articleList: result.articleList,
      //   recommendList: result.recommendList
      // }
      // dispatch(action);
      dispatch(changeHomeData(result))
    })
  }
}

export const getMoreList = (page) => {
  return (dispatch) => {
    axios.get(`/api/homeList.json?page=${page}`).then((res)=>{
      const result=res.data.data;
      dispatch(addHomeData(result,page+1));
    })
  }
}
export const toggleTopShow = (show) =>({
  type:constants.TOGGLE_SCROLL_TOP,
  show
})