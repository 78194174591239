import React, { PureComponent } from 'react';
import {WritterWrapper} from '../style';
class Writer extends PureComponent {
  render() {
    return (
      <WritterWrapper>Writer</WritterWrapper>
    )
  }
}

export default Writer;