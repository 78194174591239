// import React, { Component } from 'react';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Topic from './components/Topic';
import List from './components/List';
import Recommend from './components/Recommend';
import Writer from './components/Writer';
import { actionCreators } from './store';
import { HomeWrapper, HomeLeft, HomeRight, BackTop } from './style';
// class Home extends Component {
class Home extends PureComponent {
//PureComponent内在实现了shouldComponentUpdate,与immutable相结合，使得单个组件在store里面的数据更新时，不会连带其他组件一块渲染

  handleScrollTop() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <HomeWrapper>
        <HomeLeft>
          <img className='banner-img' src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Ffile01.dysucai.com%2Fd%2Ffile%2Flan2018061914%2F4auqsvxoe2c.jpg&refer=http%3A%2F%2Ffile01.dysucai.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1624465100&t=f100d17b4e1585d1270836b024c2b5e2" alt="" />
          <Topic />
          <List />
        </HomeLeft>
        <HomeRight>
          <Recommend />
          <Writer />
        </HomeRight>
        {
          this.props.showScroll ? <BackTop onClick={this.handleScrollTop}>顶部</BackTop>:null
        }

      </HomeWrapper>
    )
  }
  componentDidMount() {
    /*     axios.get('/api/home.json').then((res) => {
          const result = res.data.data;
          const action = {
            type: 'change_home_data',
            topicList: result.topicList,
            articleList: result.articleList,
            recommendList: result.recommendList
          }
          this.props.changeHomeData(action);
        }) */
    this.props.changeHomeData();

    this.bindEvents();
  }
  componentWillUnmount(){
    window.removeEventListener('scroll', this.props.changeScrollTopShow)
  }
  bindEvents(){
    window.addEventListener('scroll', this.props.changeScrollTopShow)
  }
}

const mapState=(state)=>({
  showScroll: state.getIn(['home','showScroll'])
})

const mapDispatch = (dispatch) => ({
  changeHomeData() {
    const action = actionCreators.getHomeInfo();
    dispatch(action);
  },
  changeScrollTopShow(){
    if (document.documentElement.scrollTop>400){
      dispatch(actionCreators.toggleTopShow(true))
    }else{
      dispatch(actionCreators.toggleTopShow(false))

    }
    // console.log(document.documentElement.scrollTop)
  }
})

export default connect(mapState, mapDispatch)(Home);